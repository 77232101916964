<template>
  <div class="body mobile">
    <!-- class: fixed, mobile -->
    <!-- Loading Spinner -->
    <!-- <div class="spinner"><div><div></div></div></div> -->
    <!-- Header -->
    <section class="header notice">
      <div class="flexH width">
        <a class="icon iconButton third" @click.prevent="goBack">
          <font-awesome-icon
            icon="fa-solid fa-chevron-left"
            size="lg"
          ></font-awesome-icon>
        </a>
      </div>
      <span class="fL nowrap font-bold font-white">圖書任務</span>
      <div class="flexH width right"></div>
    </section>

    <!-- Main -->
    <section class="main notice">
      <!-- 紀錄 -->
      <div class="records flexV width padding mt-3">
        <div style="padding-top: 30px">
          月任務
          <div
            style="
              background-color: #fff;
              border-radius: 10px;
              padding: 15px;
              margin-top: 10px;
            "
          >
            <div class="font-normal cNeutra500 fL" style="padding-bottom: 10px">
              每月借閱圖書
            </div>
            <div
              class="fS font-normal cNeutral900 font-lg fz-100 flex"
              style="
                padding-bottom: 10px;
                align-items: center;
                font-size: 1rem !important;
              "
            >
              <img
                src="@/assets/icon/coin-icon.svg"
                style="width: 18px; heigh: 18px; margin-right: 5px"
              />雲林幣 {{ monthTaskCoin }} 枚
            </div>
            <div
              class="font-normal cNeutra500 fL"
              style="padding-bottom: 10px; font-size: 1.1rem"
            >
              任務完成進度
              <span
                style="
                  background-color: #ededed;
                  border-radius: 20px;
                  padding: 5px 10px;
                  margin-left: 5px;
                  font-size: 1rem;
                "
                >{{ currentMonthTaskCount }} / {{ monthTaskCount }}</span
              >
            </div>
          </div>
        </div>
        <div style="padding-top: 60px">
          年任務
          <div
            style="
              background-color: #fff;
              border-radius: 10px;
              padding: 15px;
              margin-top: 10px;
            "
          >
            <div class="font-normal cNeutra500 fL" style="padding-bottom: 10px">
              每年借閱圖書
            </div>
            <div
              class="fS font-normal cNeutral900 font-lg fz-100 flex"
              style="padding-bottom: 10px; align-items: center; font-size: 1rem"
            >
              <img
                src="@/assets/icon/coin-icon.svg"
                style="width: 18px; heigh: 18px; margin-right: 5px"
              />雲林幣 {{ yearTaskCoin }} 枚
            </div>
            <div
              class="font-normal cNeutra500 fL"
              style="padding-bottom: 10px; font-size: 1.1rem"
            >
              任務完成進度
              <span
                style="
                  background-color: #ededed;
                  border-radius: 20px;
                  padding: 5px 10px;
                  margin-left: 5px;
                  font-size: 1rem;
                "
                >{{ currentYearTaskCount }} / {{ yearTaskCount }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment-timezone";
import { mapState } from "vuex";

export default {
  name: "",
  data() {
    return {
      latestReadAt: 0,
      status: 1,
      apiHost: process.env.VUE_APP_API_HOST,
      merchantId: process.env.VUE_APP_MERCHANT_ID,
      currentMonthTaskCount: 0,
      currentYearTaskCount: 0,
      monthTaskCount: 0,
      yearTaskCount: 0,
      monthTaskCoin: 0,
      yearTaskCoin: 0,
      readercode: "",
    };
  },
  beforeRouteEnter(to, from, next) {
    // called before the route that renders this component is confirmed.
    // does NOT have access to `this` component instance,
    // because it has not been created yet when this guard is called!
    console.log(to, from);
    next((vm) => {
      let that = vm;
      if (that.user && that.user.userId) {
        //
      } else {
        // window.location.reload();
        next("/home");
      }
    });
  },
  created() {
    if (this.$route.query.readercode !== undefined)
      this.readerCode = this.$route.query.readercode;
    this.getMonthTaskStatus();
    this.getYearTaskStatus();
  },
  mounted() {
    console.log("mounted!");
    // this.onSnapShot();

    //NOTE: Howard's fix: 現在notification直接從store取用，不用再原地去Firestore撈了
    // this.getLatestReadTime()
    // .then(() => {
    //     this.onSnapShot();
    // });

    setTimeout(() => {
      console.log("Notifications", this.notifications);
    }, 4000);
  },
  computed: {
    ...mapState(["user", "userInfo"]),
    // ...mapGetters({
    //     notifications: 'noti/notifications'
    // }),
    notifications() {
      let ns = this.$store.getters["noti/notifications"];
      // fake data
      // ns = [{id:1,title:"title1",text:"text1",ts:1720961906,unread:true},{id:2,title:"title2",text:"text2",ts:1720961906,unread:true}];
      return Object.values(ns).sort((a, b) => {
        return b.ts - a.ts;
      });
    },
  },
  methods: {
    compare(a, b) {
      if (a.ts > b.ts) {
        return -1;
      } else if (a.ts == b.ts) {
        return 0;
      } else if (a.ts < b.ts) {
        return 1;
      }
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    tsToDatetime(ts) {
      return moment(ts * 1000).format("YYYY-MM-DD HH:mm:ss");
    },
    onSnapShot() {
      let collection = db.collection(
        `notifications/yulin/topics/${this.user.user.firestoreChannelId}/messages`
      );
      // doesn't use change to vuefire bind
      collection.onSnapshot((snapshot) => {
        this.notifications = [];
        snapshot.forEach((doc) => {
          const data = doc.data();
          this.notifications.push(
            Object.assign({}, data, {
              docId: doc.id,
              unread: data.unread && data.ts > this.latestReadAt,
            })
          );
        });
        this.notifications.sort(this.compare);
        console.log("this.notifications: ", this.notifications);
        this.getLatestReadTime();
      });
    },
    getLatestReadTime() {
      return db
        .collection(
          `notifications/yulin/recipients/${this.user["userId"]}/readTopics`
        )
        .doc(this.user.user.firestoreChannelId)
        .get()
        .then((snapshot) => {
          if (!snapshot.exists) return;
          this.latestReadAt = snapshot.data().latestReadAt;
        });
    },
    updateUnreads(_latestReadAt) {
      if (_latestReadAt) this.latestReadAt = _latestReadAt;
      this.notifications.forEach((noti) => {
        if (noti.ts <= this.latestReadAt) noti.unread = false;
      });
    },
    async getMonthTaskStatus() {
      const url = `${this.apiHost}/digital_card/task_month_status/v1/merchants/${this.merchantId}/task_month_status?sso_uid=${this.user.userId}&merchant_id=${this.merchantId}&readercode=${this.readerCode}&member_id=${this.userInfo.memberId}`;

      this.resMonth = await axios({
        url,
        method: "GET",
      }).then(function (response) {
        return response.data;
      });
      if (this.resMonth.result) {
        this.monthTaskCoin = this.resMonth.data.coin;
        this.monthTaskCount = this.resMonth.data.count;
        this.currentMonthTaskCount = this.resMonth.data.currentCount;
      }
    },
    async getYearTaskStatus() {
      const url = `${this.apiHost}/digital_card/task_year_status/v1/merchants/${this.merchantId}/task_year_status?sso_uid=${this.user.userId}&merchant_id=${this.merchantId}&readercode=${this.readerCode}&member_id=${this.userInfo.memberId}`;

      this.resYear = await axios({
        url,
        method: "GET",
      }).then(function (response) {
        return response.data;
      });

      if (this.resYear.result) {
        this.yearTaskCoin = this.resYear.data.coin;
        this.yearTaskCount = this.resYear.data.count;
        this.currentYearTaskCount = this.resYear.data.currentCount;
      }
    },
  },
};
</script>

<style scoped>
.tab {
  border-radius: 0 !important;
}

.record {
  padding: 16px !important;
}

button {
  color: white;
  border-radius: 20px !important;
  height: 51px !important;
}

img {
  width: 24 !important;
  height: 24px !important;
}
</style>
